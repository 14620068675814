import React, { useEffect, useRef, useState } from 'react';
import './MainContent.css';
import destination1 from '../assets/images/destination1.jpg';
import destination2 from '../assets/images/destination2.jpg';
import destination3 from '../assets/images/destination3.jpg';
import destination4 from '../assets/images/destination4.jpg';
import destination5 from '../assets/images/destination5.jpg';
import destination6 from '../assets/images/destination6.jpg';
import destination7 from '../assets/images/destination7.jpg';
import bgImage from '../assets/images/final_background.png';
import aboutImage from '../assets/images/about_us_fin.png';
import aboutImage2 from '../assets/images/p_04.jpg';
import planeImage from '../assets/images/plane_01.png';

const App = () => {
  const scrollingWrapperRef = useRef(null);

  useEffect(() => {
    const scrollWrapper = scrollingWrapperRef.current;
    let animationFrameId;
    
    const scroll = () => {
      if (scrollWrapper.scrollLeft >= scrollWrapper.scrollWidth / 2) {
        scrollWrapper.scrollLeft = 0;
      } else {
        scrollWrapper.scrollLeft += 1;
      }
      animationFrameId = requestAnimationFrame(scroll);
    };
    
    animationFrameId = requestAnimationFrame(scroll);
    
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  useEffect(() => {
    console.log('formData changed:', formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log('Form data before sending:', formData); // Log form data before sending
    
    // Clear all input fields immediately
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: 'Message Sent!',
    });
    
    fetch('http://localhost:3001/send-email', {  // Ensure this URL matches your server URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Success:', data);
        // Optionally, you can set the message to "Message Sent!" again if needed
        setFormData((prevData) => ({
          ...prevData,
          message: 'Message Sent!',
        }));
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="app">
      <header className="header">
        <div className="button-container">
          <button className="top-button" onClick={() => scrollToSection('home')}>Home</button>
          <button className="top-button" onClick={() => scrollToSection('services')}>Services</button>
          <button className="top-button" onClick={() => scrollToSection('about-us')}>About Us</button>
          <button className="top-button" onClick={() => scrollToSection('contact-us')}>Contact Us</button>
        </div>
      </header>
      <main>
        <section id="home" className="image-section">
          <img 
            src={bgImage}
            alt="Background" 
            className="big-image" 
          />
        </section>
        <section id="services" className="popular-destinations">
          <div className="popular-destinations-header">
            <h2>Popular Destinations</h2>
            <img src={planeImage} alt="Plane" className="popular-destinations-plane" />
          </div>
          <div className="scrolling-wrapper" ref={scrollingWrapperRef}>
            <div className="scrolling-images">
              <img src={destination1} alt="Destination 1" className="destination-image" />
              <img src={destination2} alt="Destination 2" className="destination-image" />
              <img src={destination3} alt="Destination 3" className="destination-image" />
              <img src={destination4} alt="Destination 4" className="destination-image" />
              <img src={destination5} alt="Destination 5" className="destination-image" />
              <img src={destination6} alt="Destination 6" className="destination-image" />
              <img src={destination7} alt="Destination 7" className="destination-image" />
              <img src={destination1} alt="Destination 1" className="destination-image" />
              <img src={destination2} alt="Destination 2" className="destination-image" />
              <img src={destination3} alt="Destination 3" className="destination-image" />
              <img src={destination4} alt="Destination 4" className="destination-image" />
              <img src={destination5} alt="Destination 5" className="destination-image" />
              <img src={destination6} alt="Destination 6" className="destination-image" />
              <img src={destination7} alt="Destination 7" className="destination-image" />
            </div>
          </div>
        </section>
        <section id="about-us" className="about-us">
          <div className="about-us-left">
            <img src={aboutImage2} alt="About Us Left" className="about-image-left" />
          </div>
          <div className="about-us-right">
            <img src={aboutImage} alt="About Us Right" className="about-image-right" />
            <div className="about-text">
              <h2>About Us</h2>
              <p>We are a trusted flight booking service dedicated to providing excellent customer service and the best deals on flights. Our team of experts works tirelessly to ensure you have a smooth and enjoyable experience from booking to boarding. Whether you're planning a business trip or a vacation, we are here to help you every step of the way. Our mission is to make travel easy and accessible for everyone.</p>
            </div>
          </div>
        </section>
        <section id="contact-us" className="contact-us">
          <div className="contact-form">
            <div className="contact-header">
              <h2>Send us a message</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <input 
                type="text" 
                name="name"
                placeholder="Name" 
                className="contact-input" 
                value={formData.name} 
                onChange={handleChange} 
              />
              <input 
                type="email" 
                name="email"
                placeholder="Your email address" 
                className="contact-input" 
                value={formData.email} 
                onChange={handleChange} 
              />
              <input 
                type="tel" 
                name="phone"
                placeholder="Your phone number" 
                className="contact-input" 
                value={formData.phone} 
                onChange={handleChange} 
              />
              <textarea 
                name="message"
                placeholder="Write your message here..." 
                className="contact-textarea" 
                value={formData.message} 
                onChange={handleChange} 
              ></textarea>
              <button type="submit" className="contact-submit-button">
                <i className="fas fa-paper-plane"></i>
              </button>
            </form>
          </div>
          <hr className="contact-divider" />
          <div className="contact-info">
            <div className="contact-info-item">
              <a href="mailto:dibhatravelandtours966@gmail.com">
                <i className="fas fa-envelope"></i> dibhatravelandtours966@gmail.com
              </a>
            </div>
            <div className="contact-info-item">
              <a href="https://www.facebook.com/profile.php?id=61560905486685" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i> Dibha Travel and Tours Pvt. Ltd.
              </a>
            </div>
            <div className="contact-info-item">
              <a href="https://wa.me/9779767356044" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp"></i> +977-9767356044
              </a>
            </div>
            <div className="contact-info-item">
              <a href="tel:+9779767356044">
                <i className="fas fa-phone"></i> +977-9767356044
              </a>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
